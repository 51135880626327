import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from "@headlessui/react";
import { Fragment, useState } from "react";
import { l } from "./languageUtils";
import Icon from "./Icon";
import { twMerge } from "tailwind-merge";
import { gtmLogProvinceClick, type Province } from "../utils/gtm/provinceClicked";

interface ExternalLinksModalProps {
  externalLink?: string | undefined;
  className?: string;
  content?: React.ReactNode;
  lang?: "CA-fr" | "en";
  gtmtag?: Province;
  children?: React.ReactNode;
}

export default function ExternalLinksModal({
  externalLink,
  lang,
  content,
  children,
  className,
  gtmtag,
}: ExternalLinksModalProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [link, setLink] = useState<string | null>(null);

  const openModal = (e: React.MouseEvent) => {
    console.log("openModal");
    e.preventDefault();
    setIsOpen(true);
  };

  function closeModal() {
    setIsOpen(false);
  }

  const openLink = () => {
    if (link) {
      window.open(link, "_blank");
      setLink(null);
      setIsOpen(false);
    }
  };

  return (
    <Fragment>
      <a
        href={externalLink}
        onClick={e => {
          setLink(externalLink ?? null);
          openModal(e);

          if (gtmtag) {
            gtmLogProvinceClick({ province: gtmtag });
          }
        }}
        className={twMerge("underline", className)}
      >
        {children}
      </a>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={closeModal}>
          <div className="flex items-center justify-center min-h-screen">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <DialogPanel className="fixed inset-0 bg-black opacity-30" />
            </TransitionChild>

            <button
              type="button"
              className="absolute top-0 right-0 bg-blue-headers rounded-bl-lg flex items-center justify-center text-5xl hover:bg-[#5A4A88] hover:text-blue-headers focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#5A4A88] cursor-pointer"
              onClick={closeModal}
            >
              <Icon
                name="close"
                className={twMerge(" h-14 w-14 block bg-blue-headers p-2 text-white hover:bg-[#5A4A88] rounded-bl-lg")}
              />
            </button>

            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="relative bg-[#f8f8f8] rounded-3xl p-6 max-w-3xl mx-auto flex flex-col justify-center items-center">
                <Icon
                  as="div"
                  name="emptyExclamation"
                  className="flex-shrink-0 text-center text-red-500 bg-red-200 h-10 w-10 rounded-full mb-4 p-2 font-bold"
                />
                <div>
                  {lang === "CA-fr" ? (
                    <p className="text-sm text-[#6C6C6C] text-center">
                      Vous quittez maintenant le site Web <strong>empavelicoverage.ca.</strong> Sobi Canada, Inc. n'est
                      pas responsable et ne supervise, ne gère, n'exploite, ne fournit ou n'approuve d'aucune façon un
                      site Web de tiers ou tout produit, service ou contenu de ce site Web de tiers. Nos conditions
                      générales d'utilisation et notre politique de confidentialité ne s'appliquent à aucun site Web de
                      tiers et Sobi Canada, Inc. vous recommande donc de consulter les conditions d'utilisation, la
                      politique de confidentialité et/ou les politiques de sécurité des données de ces sites Web de
                      tiers.
                    </p>
                  ) : (
                    <p className="text-sm text-[#6C6C6C] text-center">
                      You are now leaving <strong>empavelicoverage.ca.</strong> Sobi Canada, Inc. is not responsible for
                      and does not in any way oversee, manage, operate, provide, or endorse any third-party website or
                      any products, services, or content on such third-party website. Our Terms of Use and Privacy
                      Policy are not applicable to any third-party website and so Sobi Canada, Inc. recommends that you
                      review the terms of use, privacy policy and/or data security policies of any such third-party
                      websites.
                    </p>
                  )}

                  <div className="mt-4 flex justify-center space-x-2">
                    <button
                      type="button"
                      onClick={() => setIsOpen(false)}
                      className="px-8 py-2 bg-gray-400 text-white rounded-full hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#5A4A88]"
                    >
                      {l("Cancel & stay here", "Annuler", lang)}
                    </button>
                    <button
                      type="button"
                      onClick={openLink}
                      className="px-8 py-2 bg-[#37B2C4] text-white rounded-full hover:bg-[#a8dbe3] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#5A4A88]"
                    >
                      {l("Continue to link", "Continuer", lang)}
                    </button>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </Dialog>
      </Transition>
    </Fragment>
  );
}
